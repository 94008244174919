<template>
  <v-container id="user-table" fluid tag="section">
    <base-panel icon="mdi-compare-vertical" :title="$t('result.quiz results')">
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="total"
        :footer-props="{
          'items-per-page-options': [20, 50, 100],
        }"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="onViewAnswers(item)">mdi-eye</v-icon>
          <v-icon
            small
            v-if="item.completed"
            class="mr-2"
            @click="onChart(item)"
            >mdi-chart-box</v-icon
          >
        </template>
        <template v-slot:[`item.created_at`]="{ item }">
          {{ moment(item.created_at).format("LLL") }}
        </template>
      </v-data-table>
    </base-panel>
  </v-container>
</template>

<script>
import crudMixin from "mixins/crudTable";
import moment from "moment";

export default {
  mixins: [crudMixin],
  components: {
  },
  data() {
    return {
      apiQuery: "/quiz-results/",
      headers: [
        { text: this.$t("result.quiz name"), value: "quiz_name" },
        { text: this.$t("result.participant"), value: "participant_name" },
        { text: this.$t("result.email time"), value: "email_sent" },
        { text: this.$t("result.end time"), value: "end_time" },
        { text: this.$t("Actions"), value: "actions", sortable: false },
      ],
      items: [],
    };
  },
  mounted() {
    moment.locale(this.$i18n.locale);
  },
  methods: {
    moment,
    onViewAnswers(item) {
      this.$router.push("/results/answers/" + item.id);
    },
    onChart(item) {
      this.download(
        this.apiQuery + item.id + '/pdf',
        item.quiz_name + "-" + item.group_name + "-" + item.id + ".pdf"
      ).catch((error) => {
        this.alert({
          text: error.message,
          type: "error",
          title: "Oops",
        });
      });
    },
  },
};
</script>
